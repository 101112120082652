import { useClientHandle } from '@urql/vue';
import gql from 'graphql-tag';
import { ref } from 'vue';
import { addMilliseconds } from 'date-fns';
import {
  mean,
  median,
  standardDeviation,
} from './math.js';
import { formatMillis } from './date.js';

const TIME_SYNC_QUERY = gql`
query TimeSync {
  time
}
`;

function calculateDelta(localTime, remoteTime, samples) {
  const now = new Date().getTime();

  const latency = (now - (localTime)) / 2;
  const currentDelta = (now - latency) - (remoteTime);
  samples.push(currentDelta);
  const mid = median(samples);
  const stddev = standardDeviation(samples);
  const culledSamples = samples.filter((ds) => Math.abs(ds - mid) <= stddev);
  const delta = Math.round(mean(culledSamples) / 5.0) * 5;
  if (Number.isNaN(delta)) {
    console.log('NaNaNaNaNa', mid, stddev, samples, samples);
  }
  if (samples.length > 100) {
    samples.splice(0, samples.length, ...culledSamples);
  }
  return {
    delta, samples, stddev, latency,
  };
}

const samples = [];
const deltaRef = ref(null);
const latencyRef = ref(null);
let locked = import.meta.env.PROD;

export default function useRealtime(maxSamples = 10, tickTime = 2000) {

  const { client } = useClientHandle();

  const tick = async () => {
    locked = true;
    if (samples.length < maxSamples || maxSamples === null) {
      const localTime = new Date();

      try {
        const response = await client.query(
          TIME_SYNC_QUERY,
          {
            time: formatMillis(localTime),
          },
          { requestPolicy: 'network-only' },
        );

        const remoteTime = response.data.time;
        const { delta, latency } = calculateDelta(
          localTime.getTime(),
          remoteTime.getTime(),
          samples,
        );
        if (delta !== deltaRef.value) {
          deltaRef.value = delta;
        }
        if (latency !== latencyRef.value) {
          latencyRef.value = latency;
        }
      } catch (e) {

      }

      setTimeout(tick, tickTime);
    }
  };

  if (!locked) {
    tick();
  }

  return {
    delta: deltaRef,
    latency: latencyRef,
    serverTime: (t) => addMilliseconds(t, deltaRef.value || 0),
    reset: async () => {
      locked = false;
      await tick();
    },
  };
}
