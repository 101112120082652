import {
  onBeforeUnmount,
  onMounted,
  ref,
  unref,
} from 'vue';
import {
  differenceInSeconds,
  isAfter,
} from 'date-fns';
import useRealtime from './realtime.js';

export default function useTimer(targetRefOrValue) {
  const now = ref(new Date());
  const target = unref(targetRefOrValue);

  const { serverTime } = useRealtime();

  let timer = null;

  onMounted(() => {
    if (!isAfter(now.value, target)) {
      setTimeout(() => {
        timer = setInterval(() => {
          const n = serverTime(new Date());
          const diff = differenceInSeconds(target, n);
          if (
            diff < 60 || n.getMinutes() !== now.value.getMinutes()
          ) {
            now.value = n;
          }

          if (isAfter(now.value, target)) {
            clearInterval(timer);
            timer = null;
          }
        }, 500);
      }, 1000 - new Date().getMilliseconds());
    }
  });

  onBeforeUnmount(() => {
    if (timer) {
      clearInterval(timer);
      timer = null;
    }
  });

  return now;
}
